import * as React from "react"
import Layout from "../../components/layout"
import WideImage from "../../components/WideImage"
import { Link } from "gatsby"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import Seo from "../../components/seo";
import Navbar from "../../components/navbar";
import ProductImage from "../../components/ProductImage"


const ProductsPage = () => (

  <Layout className="page products-page">


    <Seo title={"Products"} />

      <section className="py-3 container extra-header">
        <div className="row py-lg-3">
          <div className="col-lg-8 col-md-8 mx-auto">
            <h1 className="fw-light">Huey Products</h1>
            <p className="lead">
              Huey Co is developing a suite of real-time data products to improve energy and water use efficiency in households.
            </p>
            <p>
              Huey Co’s approach to product development draws together evidence-based behavior change insights, intuitive design and cost effective manufacturing to deliver reliable and robust solutions. Our value proposition is that our products demonstrate both end-user (household) and network (utility) benefits.
            </p>
          </div>
        </div>
      </section>


      <div className="album py-5 bg-light">
        <div className="container">
          <div className="row row-cols-1 row-cols-md-2 g-3">

            <div className="col">
              <a href="/products/huey-h2o" className="product-card card shadow link-unstyled" role="button">
                <StaticImage
                  layout="fullWidth"
                  aspectRatio={3 / 2}
                  alt=""
                  src="../../images/huey-mobile-and-h2o.jpeg"
                  //formats={["auto", "webp", "avif"]}
                />
                <div className="title-band">
                  <h5 className="card-title fw-bold">Huey H₂O</h5>
                  <h6 className="card-subtitle mb-2">Water Consumption Sensor</h6>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    Provides realtime water usage data. Compatible with most Australian water meters.
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <small className="text-muted">In Trial for Release in early 2022</small>
                  </div>
                </div>
              </a>
            </div>

            <div className="col">
              <div className="product-card card">

                <div className="ratio ratio-3x2" style={{width: '100%', padding: 0, color: '#666666'}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="currentColor" className="bi bi-cpu-fill" viewBox="0 0 16 16">
                    <path d="M6.5 6a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/>
                    <path
                      d="M5.5.5a.5.5 0 0 0-1 0V2A2.5 2.5 0 0 0 2 4.5H.5a.5.5 0 0 0 0 1H2v1H.5a.5.5 0 0 0 0 1H2v1H.5a.5.5 0 0 0 0 1H2v1H.5a.5.5 0 0 0 0 1H2A2.5 2.5 0 0 0 4.5 14v1.5a.5.5 0 0 0 1 0V14h1v1.5a.5.5 0 0 0 1 0V14h1v1.5a.5.5 0 0 0 1 0V14h1v1.5a.5.5 0 0 0 1 0V14a2.5 2.5 0 0 0 2.5-2.5h1.5a.5.5 0 0 0 0-1H14v-1h1.5a.5.5 0 0 0 0-1H14v-1h1.5a.5.5 0 0 0 0-1H14v-1h1.5a.5.5 0 0 0 0-1H14A2.5 2.5 0 0 0 11.5 2V.5a.5.5 0 0 0-1 0V2h-1V.5a.5.5 0 0 0-1 0V2h-1V.5a.5.5 0 0 0-1 0V2h-1V.5zm1 4.5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3A1.5 1.5 0 0 1 6.5 5z"/>
                  </svg>
                </div>

                <div className="title-band">
                  <h5 className="card-title fw-bold">Huey Watts (Coming Soon)</h5>
                  <h6 className="card-subtitle mb-2">Power Sensor</h6>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    Measures energy circuits for real time consumption and solar balance. Can be installed into any mains meter box.
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <small className="text-muted">Mid 2022</small>
                  </div>
                </div>
              </div>
            </div>

            {/*<div className="col">*/}
            {/*  <a href="/products/huey-home" className="product-card card shadow link-unstyled" role="button">*/}
            {/*    <StaticImage*/}
            {/*      layout="fullWidth"*/}
            {/*      aspectRatio={3 / 2}*/}
            {/*      alt=""*/}
            {/*      src="../../images/huey-home-43-800.jpeg"*/}
            {/*      //formats={["auto", "webp", "avif"]}*/}
            {/*    />*/}
            {/*    <div className="title-band">*/}
            {/*      <h5 className="card-title fw-bold">Huey Home</h5>*/}
            {/*      <h6 className="card-subtitle mb-2">In-home Display</h6>*/}
            {/*    </div>*/}
            {/*    <div className="card-body">*/}
            {/*      <p className="card-text">*/}
            {/*        Provides simple, real-time display of energy and water metrics in the home to enable all householders to optimise consumption*/}
            {/*      </p>*/}
            {/*      <div className="d-flex justify-content-between align-items-center">*/}
            {/*        <small className="text-muted">Manufactured Prototype</small>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </a>*/}
            {/*</div>*/}

          </div>
        </div>
      </div>
  </Layout>
)

export default ProductsPage
